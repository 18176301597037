/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item {
  display: block;
  float: left;
}
.menu {
  display: block;
  color: #999;
}
.menu:hover,
.menu:focus,
a.path {
  color: #222;
}
div.sub1 > .item {
  margin-right: 20px;
  position: relative;
}
div.sub1 > .exit {
  margin-right: 0;
}
div.sub1 .menu,
div.sub1 .menu:visited {
  color: #939598;
  font-size: 14px;
  text-transform: uppercase;
}
div.sub1 a.path {
  color: #000 !important;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #000;
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 100;
  width: auto;
  padding-top: 6px;
}
div.sub2 > .item {
  width: 100%;
}
div.sub2 .menu,
div.sub2 .menu:visited {
  white-space: nowrap;
  display: block;
  width: 100%;
  color: #6d6e71;
  text-transform: none;
  padding: 0 6px;
  background: url(/images/bg-sub2.png) repeat 0 0;
  border: 1px solid #e6e7e8;
}
div.sub2 a.path {
  color: #f1f2f2 !important;
  background: #6d6e71 !important;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #f1f2f2;
  background: #6d6e71;
}
.dock {
  display: block;
  float: left;
}
.meta,
.meta:visited {
  color: green;
}
/******* layout-large.less 2013-1-16 *******/
#desk {
  width: 1200px;
}
.cb-layout4 #desk {
  width: 2400px;
  max-width: 94%;
}
#head {
  width: 1200px;
  margin: 0;
}
.cb-layout4 #head {
  width: 100%;
}
.cb-layout2 #head,
.cb-layout3 #head {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.cb-layout1 #head {
  width: 376px;
  margin-top: 32px;
}
#expo {
  width: 1200px;
  float: left;
  margin: 0;
}
.cb-layout4 #expo {
  width: 100%;
}
#expo .cb-index {
  width: auto;
}
#expo .cb-index td a {
  margin-left: 10px;
}
#root #expo div.link a.prev:hover,
#root #expo div.link a.prev:focus {
  background: transparent url(/images/prev-2019-03-18.svg) no-repeat 0 50%;
  background-size: 50px 60px;
}
#root #expo div.link a.next:hover,
#root #expo div.link a.next:focus {
  background: transparent url(/images/next-2019-03-18.svg) no-repeat right 50%;
  background-size: 50px 60px;
}
#slides {
  height: 800px;
}
.slide {
  width: 100%;
}
#view.cb-layout3 .seam .pict > a:after {
  text-indent: -9999px;
}
div.sub1 {
  float: right;
}
.cb-layout1 #maincontent {
  width: 394px;
}
#content {
  width: 1200px;
  margin-top: 124px;
}
.cb-layout1 #content {
  margin-top: 10px;
}
.cb-layout4 #content {
  width: 100%;
  margin-top: 20px;
}
#mood {
  width: 1200px;
  height: 800px;
  margin-top: 124px;
}
.cb-layout4 #mood {
  width: 100%;
  height: auto;
}
#footer {
  width: 1200px;
  margin-top: 77px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout4 #footer {
  width: 100%;
}
.vcard {
  width: auto;
}
#maincontent {
  width: 100%;
  margin: 0;
}
.top {
  margin-right: 0;
}
.cb-layout4 div.main {
  font-size: 20px;
  line-height: 1.4;
}
.cb-layout4 div.main div.unit div.part.pict a:after {
  font-size: 48px;
  line-height: 1.16;
}
.cb-layout4 .unit h2 {
  font-size: 30px;
}
.area {
  width: 1236px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 1236px;
}
.area h2,
.area .foot {
  margin-right: 18px;
  margin-left: 18px;
}
.area .part,
.area > .grid table {
  margin-right: 18px;
  margin-left: 18px;
  width: 1200px;
}
.area .tiny {
  width: 582px;
}
.area > .slim {
  width: 412px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 376px;
}
.area > .slim .tiny {
  margin-right: 18;
  margin-left: 18;
  width: 170px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 18px;
  margin-left: 18px;
}
.cb-layout1 .main {
  width: 412px;
}
.cb-layout1 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 412px;
}
.cb-layout1 .main h2,
.cb-layout1 .main .foot {
  margin-right: 18px;
  margin-left: 18px;
}
.cb-layout1 .main .part,
.cb-layout1 .main > .grid table {
  margin-right: 18px;
  margin-left: 18px;
  width: 376px;
}
.cb-layout1 .main .tiny {
  width: 170px;
}
.cb-layout1 .main > .slim {
  width: 206px;
}
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  width: 170px;
}
.cb-layout1 .main > .slim h2,
.cb-layout1 .main > .slim .foot,
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  margin-right: 18px;
  margin-left: 18px;
}
.cb-layout2 .area {
  width: 1236px;
}
.cb-layout2 .area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 1236px;
}
.cb-layout2 .area h2,
.cb-layout2 .area .foot {
  margin-right: 18px;
  margin-left: 18px;
}
.cb-layout2 .area .part,
.cb-layout2 .area > .grid table {
  margin-right: 18px;
  margin-left: 18px;
  width: 1200px;
}
.cb-layout2 .area .tiny {
  width: 582px;
}
.cb-layout2 .area > .slim {
  width: 412px;
}
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  width: 376px;
}
.cb-layout2 .area > .slim .tiny {
  margin-right: 18;
  margin-left: 18;
  width: 170px;
}
.cb-layout2 .area > .slim h2,
.cb-layout2 .area > .slim .foot,
.cb-layout2 .area > .slim .part,
.cb-layout2 .area > .slim.grid table {
  margin-right: 18px;
  margin-left: 18px;
}
.cb-layout3 .area {
  width: 1206px;
}
.cb-layout3 .area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 1206px;
}
.cb-layout3 .area h2,
.cb-layout3 .area .foot {
  margin-right: 3px;
  margin-left: 3px;
}
.cb-layout3 .area .part,
.cb-layout3 .area > .grid table {
  margin-right: 3px;
  margin-left: 3px;
  width: 1200px;
}
.cb-layout3 .area .tiny {
  width: 597px;
}
.cb-layout3 .area > .slim {
  width: 402px;
}
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  width: 396px;
}
.cb-layout3 .area > .slim .tiny {
  margin-right: 3;
  margin-left: 3;
  width: 195px;
}
.cb-layout3 .area > .slim h2,
.cb-layout3 .area > .slim .foot,
.cb-layout3 .area > .slim .part,
.cb-layout3 .area > .slim.grid table {
  margin-right: 3px;
  margin-left: 3px;
}
.cb-layout4 .main {
  width: 100%;
}
.cb-layout4 .main > .unit {
  margin-right: 0.81967213%;
  margin-left: 0.81967213%;
  width: 98.36065574%;
}
.cb-layout4 .main h2,
.cb-layout4 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout4 .main .part,
.cb-layout4 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout4 .main > .slim {
  width: 31.69398907%;
}
.cb-layout4 .main > .slim h2,
.cb-layout4 .main > .slim .foot,
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  width: 100%;
}
.cb-layout4 .main > .slim .tiny {
  width: 100%;
}
.side {
  width: 824px;
}
.side > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 824px;
}
.side h2,
.side .foot {
  margin-right: 18px;
  margin-left: 18px;
}
.side .part,
.side > .grid table {
  margin-right: 18px;
  margin-left: 18px;
  width: 788px;
}
.side .tiny {
  width: 376px;
}
.side > .slim {
  width: 412px;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 376px;
}
.side > .slim .tiny {
  margin-right: 18;
  margin-left: 18;
  width: 170px;
}
.side > .slim h2,
.side > .slim .foot,
.side > .slim .part,
.side > .slim.grid table {
  margin-right: 18px;
  margin-left: 18px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.wide.resp .swap > table {
  float: left;
  width: 70% !important;
}
.wide.resp > .foot > .scan > .ctrl {
  margin-top: 0;
}
.wide p.topi {
  float: left;
  width: 60%;
}
.wide .resp > .link {
  text-align: right;
}
.wide .resp > .link > .open {
  float: left;
  width: 60%;
  text-align: left;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-left: -18px;
}
.cb-layout3 .main {
  margin-left: -3px;
  margin-top: 9px;
}
.cb-layout4 .main {
  width: 101.66666667%;
  margin-left: -0.83333333%;
}
.main {
  margin-left: -18px;
}
.side {
  margin-right: -18px;
  margin-top: 24px;
}
.base {
  margin-left: -18px;
  margin-top: 13px;
}
.cb-layout2 .main {
  margin-top: 9px;
}
.cb-layout2 .main .unit {
  margin-top: 18px;
  margin-bottom: 18px;
}
.cb-layout2 .main .unit.wide {
  width: 824px;
}
.cb-layout2 .main .unit.wide div.tall {
  width: 788px;
}
.cb-layout2 .main .unit.slim {
  width: 412px;
}
.cb-layout2 .main .unit.slim div.tall .cb-layout2 .main .unit.wide div.tiny {
  width: 376px;
}
.cb-layout2 .main .unit.slim div.tall,
.cb-layout2 .main .unit.wide div.tiny {
  width: 376px;
}
.cb-layout2 .main .unit.slim div.tiny {
  width: 170px;
}
.cb-layout3 div.main div.unit .part {
  margin-top: 18px;
  margin-bottom: 18px;
}
.cb-layout2 div.main div.wide {
  width: 100%;
}
.cb-layout2 div.main div.slim div.part {
  margin-left: 18px;
  margin-right: 18px;
}
.cb-layout2 div.main div.head {
  margin-top: -3px;
}
.cb-layout2 div.main div.part {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout4 div.main div.unit.wide {
  width: 48.36065574%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -1280px;
  width: 2560px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
#disp a.capt {
  width: 50%;
}
#disp input.capt {
  float: right;
  width: 48%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.cb-photoeditor {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.cb-lightbox-code {
  left: 50%;
  margin-left: -240px;
  width: 480px;
}
#disp.shop,
#disp.cb-shoppingcart {
  left: 50%;
  margin-left: -265px;
  width: 530px;
}
h1,
h2 {
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #ffffff;
  color: #4c4c4c;
}
.same,
.mese .same > span {
  background-color: #ffffff;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #d4d5d6;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 18px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url(/icons/prev_next.png);
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -54px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -18px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -36px;
}
.cb-album .next {
  background-position: -17px -54px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -17px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -17px -18px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -17px -36px;
}
.cb-manual > .head > h2,
#edit .cb-slideshow > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
/******* module-filter-large.less 2013-1-16 *******/
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span {
  padding: 0;
}
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city {
  width: 56%;
}
#disp.shop .cb-shop-currency > select {
  width: 60%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */